/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };


  function configurer(){
      
      /* HOMEPAGE */
if($('body').hasClass('home')){
      $('#front-fullpage').fullpage({
      //options here
      scrollOverflow: true,
      licenseKey: null,
      anchors: ['front-header-section','front-approche-section', 'front-why', 'front-how', 'front-services'],

      onLeave: function(index, nextIndex, direction){

        if(nextIndex.index == 1){
          $('#fixed-nav').addClass('visible');
          $('#fixed-nav').removeClass('small');

          $('#about-left').removeClass('opacity');
          setTimeout(function() {
            $('#about-left').removeClass('visible');
          }, 500);
        }

        if(nextIndex.index == 0){
          $('#fixed-nav').removeClass('visible');
        }

        if(nextIndex.index == 2){
          $('#fixed-nav').addClass('small');
          setTimeout(function() {            
            $('#about-left').addClass('visible');
            $('#about-left').addClass('opacity');
          }, 500);
        }

        if(nextIndex.index == 3){
           setTimeout(function() {            
            $('#about-left').addClass('visible');
            $('#about-left').addClass('opacity');
          }, 500);


          $('#fixed-nav').removeClass('background');
        }

        if(nextIndex.index == 4){

          $('#about-left').removeClass('opacity');
          setTimeout(function() {
            $('#about-left').removeClass('visible');
          }, 500);

          $('#fixed-nav').addClass('background');
        }

        if(nextIndex.index == 2 || nextIndex.index == 3){
          $('#fixed-nav').addClass('white-half');
        }else {
          $('#fixed-nav').removeClass('white-half');
        }


      }
    });

    $('#home-services .service').on('mouseenter', function() {
      $(this).addClass('active');
      $(this).find('.content').slideDown();
    });

    $('#home-services .service').on('mouseleave', function() {
      $(this).removeClass('active');
      $(this).find('.content').slideUp();
    });
}    

    // APPROCHE

    if($('body').hasClass('page-template-page-approche')) {

      $(window).on('scroll', function() {
          scrollPosition = $(this).scrollTop();

          var winHeight = $(window).height()/2;

          if (scrollPosition >= winHeight) {
            $('#fixed-nav').addClass('visible small background');
          }else {
            $('#fixed-nav').removeClass('visible small background');
          }
      });

    }

    // EQUIPE

    if($('body').hasClass('page-template-page-equipe')) {

      $(window).on('scroll', function() {
          scrollPosition = $(this).scrollTop();

          var winHeight = $(window).height();

          if($(window).width() <= 992){

            if (scrollPosition >= winHeight) {
              $('#fixed-nav').addClass('visible small background');
            }else {
              $('#fixed-nav').removeClass('visible small background');
            }

          }else {
            $('#fixed-nav').removeClass('visible small background');
          }
      });
      if($(window).width() > 992){
          $('#equipe-fullpage').fullpage({
          //options here
          scrollOverflow: true,
          licenseKey: null,

          onLeave: function(index, nextIndex, direction){

          }
        });
      }

    }

    //Services 
    if($('body').hasClass('page-template-page-services')) {
      if($(window).width() > 992){
          $('#service-fullpage').fullpage({
          //options here
          scrollOverflow: true,
          licenseKey: null,      

          onLeave: function(index, nextIndex, direction){
            if(nextIndex.index >= 1 && nextIndex.index < 4){

              $('#fixed-nav').addClass('visible');
              $('#fixed-nav').removeClass('small');
              
              setTimeout(function() {
                $('#service-nav').addClass('visible');
              },500);
              
            }else {
              $('#service-nav').removeClass('visible');
              $('#fixed-nav').removeClass('visible');
            }

            $('#service-nav li.active').removeClass('active');

            $('#service-nav li[data-slide-id="'+nextIndex.index+'"]').addClass('active');

          }

        });

        $('#service-nav li').on('click', function() {
          var slideIndex = parseInt($(this).attr('data-slide-id'))+1;

          fullpage_api.moveTo(slideIndex, 1);

        });
      }
    }

    //Contact

    $('#contact-form-slider').slick({
      dots: false,
      arrows: false,
      swipe: false,
      fade: true,      
      adaptiveHeight: true
    });

    $('input').on('focusout', function(){
        var value = $(this).val();
        if(value){
          $(this).addClass('has-value');
          $(this).removeClass('error');
        }else {
        }

    });

    $('#contact-form-slider .next-slide').on('click', function(){
      var validForm = true;

      if(!$('#firstname').val()) {
        validForm = false;
        $('#firstname').addClass('error');
      }else {
        $('#firstname').removeClass('error');
      }


      if(!$('#lastname').val()) {
        validForm = false;
        $('#lastname').addClass('error');
      }else {
        $('#lastname').removeClass('error');
      }

      if(!$('#tel-840').val()) {
        validForm = false;
        $('#tel-840').addClass('error');
      }else {
        $('#tel-840').removeClass('error');

        if(!isValidPhoneNumber($('#tel-840').val())){
          validForm = false;
          $('#tel-840').addClass('error');
        }else {
          $('#tel-840').removeClass('error');
        }

      }

      if(!$('#email-433').val()) {
        validForm = false;
        $('#email-433').addClass('error');
      }else {
        if(!isValidEmailAddress($('#email-433').val())){
          validForm = false;
          $('#email-433').addClass('error');
        }else {
          $('#email-433').removeClass('error');
        }

        
      }

      if(validForm){

        $('#contact-form-slider').slick('slickNext');
        $('.form-notice').slideUp();

      }else {
        $('.form-notice').slideDown();
      }
    });

    $('.contact-form form').on('submit', function(){

      if(!$('#textarea-419').val()) {
        $('#textarea-419').addClass('error');
      }else {
         $('#textarea-419').removeClass('error');
      }
    });

    $('.contact-form form').on('keyup keypress', function(e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 13) { 
        e.preventDefault();
        return false;
      }
    });


    //MENU
    $('.menu-trigger').on('click', function(){
      $('#main-menu').addClass('visible');
    });

    $('.menu-close').on('click', function(){
      $('#main-menu').removeClass('visible');
    });

    $('.nav-propriete-slider').slick({
      fade: true,
      dots: false,
      arrows: true
    });


    //Anchor animations
    $('a[href^="#"]').on('click touchend', function() {
        var the_id = $(this).attr("href");
        var headerHeight = $('#fixed-nav').outerHeight();

        if(!$(this).hasClass('fullpage-anchor')){

          if(the_id !== '#') {

             $('html, body').animate({
                scrollTop: $(the_id).offset().top-headerHeight
              }, 'slow');
          }
          return false;

        }

      });

  }

  function isValidEmailAddress(emailAddress) {
      var pattern = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i);
      return pattern.test(emailAddress);
  };

  function isValidPhoneNumber(phoneNumber) {
      var pattern = new RegExp(/[0-9 -()+]+$/);
      return pattern.test(phoneNumber);
  };

  window.onload = configurer;

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
